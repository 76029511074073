import React, { useState } from "react";
import newsletterStyles from "./../styles/newsletter.module.css";

const NewsletterInput = () => {
  const [email, setEmail] = useState("");
  const handleChange = (value) => {
    setEmail(value);
  };
  return (
    <div className={newsletterStyles.container}>
      <span className={newsletterStyles.subscribeMessage}>
        Subscribe to our mailing list to get the latest updates!
      </span>
      <div id="mc_embed_signup">
        <form
          action="https://aiforensics.us21.list-manage.com/subscribe/post?u=0747b833cebb9a90951c3828f&amp;id=8e2489756c&amp;f_id=0005a9e1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
        >
          <div
            id="mc_embed_signup_scroll"
            className={newsletterStyles.formContainer}
          >
            <div className={newsletterStyles.mcFieldGroup}>
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                value={email}
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_0747b833cebb9a90951c3828f_8e2489756c"
                tabIndex="-1"
                value=""
                readOnly
              />
            </div>
            <div className={newsletterStyles.buttonContainer}>
              <input
                type="submit"
                value="Join"
                name="subscribe"
                id="mc-embedded-subscribe"
                className={newsletterStyles.button}
                readOnly
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterInput;
