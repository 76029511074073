import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import About from "./../markdown/about.md";
import AboutMobile from "./../markdown/aboutMobile.md";
import LogoSprite from "../components/LogoSprite";
import Accordion from "../components/Accordion";
import aboutpageStyles from "./../styles/aboutpage.module.css";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function AboutPage() {
  const [aboutText, setAboutText] = useState("");
  const [aboutTextMobile, setAboutTextMobile] = useState("");
  const { trackPageView } = useMatomo();

  useEffect(() => {
    fetch(About)
      .then((res) => res.text())
      .then((text) => setAboutText(text));
  });
  useEffect(() => {
    fetch(AboutMobile)
      .then((res) => res.text())
      .then((text) => setAboutTextMobile(text));
  });

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <div className={aboutpageStyles.about_container}>
      <ReactMarkdown
        className={aboutpageStyles.only_desktop}
        children={aboutText}
        linkTarget="_blank"
      />
      <ReactMarkdown
        className={aboutpageStyles.only_mobile}
        children={aboutTextMobile}
        linkTarget="_blank"
      />
      {aboutText && <Accordion />}
      {aboutText && <LogoSprite />}
      {aboutText && (
        <Link className={aboutpageStyles.link} to="/">
          Back to home
        </Link>
      )}
    </div>
  );
}
