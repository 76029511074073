import { useEffect } from "react";
import { Link } from "react-router-dom";
import notFoundStyles from "./../styles/notfound.module.css";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const NotFound = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <div className={notFoundStyles.container}>
      <h2 className={notFoundStyles.h2}>404</h2>
      <h1 className={notFoundStyles.h1}>Oops! You seem to be lost...</h1>
      <p>
        Go back <Link to="/">Home</Link>
      </p>
    </div>
  );
};

export default NotFound;
