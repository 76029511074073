import { useEffect } from "react";
import NewsletterInput from "../components/NewsletterInput";
import homepageStyles from "./../styles/homepage.module.css";
import { Link } from "react-router-dom";
import Socials from "../components/Socials";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function HomePage() {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <div className={homepageStyles.home_container}>
      {/* underline this text */}
      <h2 className={homepageStyles.alert_container}>
        Our website is currently under development
      </h2>
      <div className={homepageStyles.text_container}>
        <p>
          AI Forensics is a European non-profit that investigates influential
          and opaque algorithms. We hold major technology platforms accountable
          by conducting independent and high-profile technical investigations to
          uncover and expose the harms caused by their algorithms.
        </p>
        <Link to="/about">Read more about us</Link>
      </div>

      <NewsletterInput />
      <Socials />
    </div>
  );
}
