import React from "react";
import eyeaif from "./../assets/eyeaif.png";
import DesignRequirementsSyles from "./../styles/designrequirementspage.module.css";

const DesignRequirements = () => {
  return (
    <div className={DesignRequirementsSyles.container}>
      <h1>AI Forensics Branding & Website Brief</h1>
      <div className={DesignRequirementsSyles.blockContainer}>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Branding & Web design</h2>
          <div>
            A well-designed website is important. A good first impression
            matters and our credibility as a digital organisation depends a lot
            on web design. Approach opportunities also depend on the likability
            and intuitiveness of the website. What is communicated to the
            outside is not only our personality, but also the way we work. An
            organic site ensures a design and analytical skills. With the
            appropriate UI/UX we are going to be able to funnel the visitors to
            the important areas and show them the curated information. This
            information (texts, descriptions, copies) needs to be concise,
            direct and engaging.
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Objective/Current ask</h2>
          <div>
            On a high level, it must be a comprehensive showcase of all our work
            and efforts, communicate our expertise and experience in algorithmic
            auditing. It must reflect updated messages AIF wants to spread and
            its mission, vision and values. It should rank us alongside our
            “competitors” but differentiate us in terms of technical ability,
            critical perspective. It should attract funders and best recruits.
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Target audience</h2>
          <div>
            Most likely who will see the design will be international info
            seekers, researchers, journalists and donors, as well as affiliated
            organisations. They will probably find it online, in some newspapers
            articles or from academic references. They could also find us at
            conferences. An online user activity we could consider a success is
            surely contact members of our team and ask for (or subscribe if we
            want to commit to it to) the newsletter. Another measure for success
            will be feedback from founders and peers.
            <ul>
              <li>
                <strong>Funders:</strong> the website communicates with are a
                credible organization with a solid track record of past work,
                credible past funders, expert team and clear vision & mission.
                Therefore, funders want to support us.
              </li>
              <li>
                <strong>Journalists:</strong> it is easy for them to find our
                team and contact them for their expertise. They can easily find
                our reports, quote us and get in touch.
              </li>
              <li>
                <strong>Other civil society orgs & researchers:</strong> They
                can understand our credibility through our past work, they can
                assess whether our values align. They access our reports, papers
                & similar and they get in touch to collaborate.
              </li>
            </ul>
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Style guidelines or Design Criteria</h2>
          <div>
            We have established a temporary logo, fonts and color palette that
            can be improved. We wanted the logo to be simple, bold and
            recognisable through the use of typeface rather than a graphic logo.
            Our brand identity should communicate that we are a tech-savvy
            non-profit, we are rigorous and dynamic. In general we are aiming
            for mostly black, white, gray on our website and brand identity. We
            want to use a splash of colors (electric blue, purple, pink) in
            order to highlight and add emphasis. <br />
            <br /> Design should not portray a “startup” feel, no google fonts,
            still a clean way, we like
            <strong> brutalist aesthetic</strong> but the website shouldn't be
            too artistic. Moodboards will be shared during the first meeting but
            this
            <a
              target="_blank"
              href="https://www.are.na/buse/ai-forensics-j0g7zqgmybw"
              rel="noreferrer"
            >
              arena link
            </a>
            channel can show inspirations in the meantime.
            <img src={eyeaif} className="mozilla-logo" alt="mozilla-logo" />
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Information Architecture</h2>
          <div>
            We need a website of 5 to 7 main pages, with a media coverage page,
            a resources page/library(where we should also put our reports, with
            search and filter functionality), website keyword search form and a
            press section. Our success depends on how simple, comprehensible and
            explanatory the website is and on how easily it can be updated.
            <br />
            <br />
            The website navigation structure should be something like the
            following, alternative suggestions on page mapping are encouraged.
            <ul className={DesignRequirementsSyles.borderedList}>
              <li>
                <strong>Home</strong>
              </li>
              <li>
                <strong>About</strong>
                <ol>
                  <li>Our story</li>
                  <li>Core values/Manifesto</li>
                  <li>Our team (20 profiles)</li>
                  <li>Our partners</li>
                  <li>Press kit</li>
                </ol>
              </li>
              <li>
                <strong>Projects</strong>
                <ol>
                  <li>
                    TBD Tiktok observatory (and tiktok global observatory is a
                    sub
                  </li>
                  <li>project or a sibling?) </li>
                  <li>Youtube tracking exposed </li>
                  <li>Guardoni </li>
                  <li>Pornhub</li>
                  <li>The ZKDD library </li>
                  <li>Riders</li>
                </ol>
              </li>
              <li>
                <strong>What’s new</strong>
              </li>
              <li>
                <strong>Blog</strong>
              </li>
              <li>
                <strong>Press releases</strong>
              </li>
              <li>
                <strong>Media coverage</strong>
              </li>
              <li>
                <strong>Events</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <h2>Project Management Details</h2>
          <div>
            <strong>Project timeline:</strong> The website will launch by the
            end of September 2023. The designer will work with the
            communications lead and the developer for the artistic direction and
            the approval of designs. The website should be ready as soon as
            possible so depending on the designers availability, the designer
            have two weekly-check-ins with the project leads.
            <strong>Skills:</strong> Graphic design, branding, UX/UI, animation,
            3D animation.
            <br />
            <br />
            <strong className={DesignRequirementsSyles.highlight}>
              List of Deliverables
            </strong>
            <ul>
              <li> Primary logo (second priority)</li>
              <li> Alternate logo (second priority)</li>
              <li>3D animated logo (second priority)</li>
              <li> Color palette (second priority)</li>
              <li>Font suggestions (second priority)</li>
              <li> Brand style guide (second priority)</li>
              <li>
                Brand collateral
                <ol>
                  <li>Business card designs </li>
                  <li>Social Media Templates </li>
                  <li>Social media profile pictures & banners </li>
                  <li>Email Signature </li>
                  <li>Open house signs </li>
                  <li>Listing signs </li>
                  <li>Newsletter email header</li>
                </ol>
              </li>
            </ul>
            <strong className={DesignRequirementsSyles.highlight}>
              Website design
            </strong>
            <ul>
              <li>Site map</li>
              <li>Wireframes on Figma </li>
            </ul>
            <strong className={DesignRequirementsSyles.highlight}>
              Requirements
            </strong>
            <ul>
              <li>Mobile responsive</li>
              <li>SEO Friendly</li>
            </ul>
          </div>
        </div>
        <div className={DesignRequirementsSyles.blockTextContainer}>
          <a
            className={DesignRequirementsSyles.linkto}
            href="mailto:buse@aiforensics.org"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesignRequirements;
