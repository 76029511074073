import React from "react";
import accordionStyles from "./../styles/accordion.module.css";

const Accordion = () => {
  return (
    <div>
      <div className={accordionStyles.only_mobile}>
        <div className={accordionStyles.tabs}>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck1"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck1">
              Our story
            </label>
            <div className={accordionStyles.tab_content}>
              AI Forensics was co-founded in 2021 by Marc Faddoul and Claudio
              Agosti, to continue and expand the activities of the project{" "}
              <a target="blank" href="https://tracking.exposed/">
                Tracking Exposed
              </a>{" "}
              active since 2016 in algorithmic acountability. The organization
              is registered in France, as a non-profit organisation. (
              <em>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>Association loi de 1901</strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </em>{" "}
              )
            </div>
          </div>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck2"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck2">
              Our mission
            </label>
            <div className={accordionStyles.tab_content}>
              We hold influential and opaque algorithms accountable by exposing
              algorithmic harms, to increase the power and agency for the many,
              not the few.
            </div>
          </div>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck3"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck3">
              Our vision
            </label>
            <div className={accordionStyles.tab_content}>
              We envision a world where trustworthy technology creates a fairer
              future for all, because algorithms are accountable, adjustable and
              avoidable.
            </div>
          </div>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck4"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck4">
              What do we do
            </label>
            <div className={accordionStyles.tab_content}>
              <strong>We develop our own auditing tools</strong>, which we
              release as&nbsp;<strong>free software</strong>&nbsp;to empower the
              research community, and grow the AI audit ecosystem. We believe
              that&nbsp;
              <strong>consistent, coordinated scrutiny</strong>
              &nbsp;is the path to&nbsp;
              <strong>restoring the imbalance of power</strong>&nbsp;between big
              tech platforms and those who use them.
              <br></br>
              Our&nbsp;<strong>unique data-driven approach</strong>
              &nbsp;gives&nbsp;
              <strong>
                journalists, researchers, regulators and lawmakers
              </strong>
              &nbsp;timely evidence of the systematic violations&nbsp;
              <strong>the user's interests and digital rights,</strong>
              &nbsp;particularly minority groups and communities that are
              overlooked in the technological design.
              <ul className={accordionStyles.ulist}>
                <li>
                  <em>
                    <strong>Collecting evidence</strong>
                  </em>{" "}
                  - we develop free-software to enable&nbsp;
                  <strong>independent, adversarial algorithmic audits</strong>
                </li>
                <li>
                  <em>
                    <strong>Analyse and interpret the evidence</strong>
                  </em>{" "}
                  - we&nbsp;conduct <strong>special investigations</strong>
                  &nbsp;to expose platform malpractices
                </li>
                <li>
                  <em>
                    <strong>Testify</strong>
                  </em>{" "}
                  - we force platform change through&nbsp;
                  <strong>scrutiny, regulation and advocacy</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck5"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck5">
              Team
            </label>
            <div className={accordionStyles.tab_content}>
              AI Forensics is powered by a talented and motivated team which
              contributes their skills accross the various projects:
              <ul className={accordionStyles.ulist}>
                <li>
                  <strong>Alvaro de Frutos:</strong>&nbsp;Full-stack
                  developement
                </li>
                <li>
                  <strong>Andrea Ascari:</strong>&nbsp;Full-stack developement
                </li>
                <li>
                  <strong>Andrea T:</strong>&nbsp;Site Reliability and System
                  Administration
                </li>
                <li>
                  <strong>Claudio Agosti:</strong>&nbsp;Algorithms Explorer and
                  Digital Rights Evangelist
                </li>
                <li>
                  <strong>Katya V:</strong>&nbsp;Political Science and Advocacy,
                  Fundraising
                </li>
                <li>
                  <strong>Marc Faddoul:</strong>&nbsp;Algorithmic Design,
                  Strategy
                </li>
                <li>
                  <strong>Miazia Schüler:</strong>&nbsp;Digital Media, Research
                </li>
                <li>
                  <strong>Natalie Kerby:</strong>&nbsp;Digital Media, Research
                </li>
                <li>
                  <strong>Raziye Buse Çetin:</strong>&nbsp;AI Ethics and Policy,
                  Communication
                </li>
                <li>
                  <strong>Simone Robutti</strong>: Data Engineering,
                  Organisational Design
                </li>
                <li>
                  <strong>Salvatore Romano:</strong>&nbsp;Platform
                  accountability, Research
                </li>
              </ul>
            </div>
          </div>
          <div className={accordionStyles.tab}>
            <input
              className={accordionStyles.inputhidden}
              type="checkbox"
              id="chck6"
            />
            <label className={accordionStyles.tab_label} htmlFor="chck6">
              Legal Board
            </label>
            <div className={accordionStyles.tab_content}>
              AI Forensics is governed collegially by four co-presidents:
              <ul className={accordionStyles.ulist}>
                <li>
                  <a target="blank" href="https://www.linkedin.com/in/vecna/">
                    Claudio Agosti
                  </a>
                  &nbsp;is a privacy-activist pioneer, with 20-years experience
                  building free-software. He is the founder of Tracking Exposed.
                </li>
                <li>
                  <a target="blank" href="http://www.marcfaddoul.com/">
                    Marc Faddoul
                  </a>
                  &nbsp;is a transdisciplinary technologist. Data Scientist by
                  training, he carried social media research at UC Berkeley and
                  Facebook AI.
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://en.wikipedia.org/wiki/Primavera_De_Filippi"
                  >
                    Primavera de Filippi
                  </a>
                  &nbsp;is a legal scholar and blockchain expert, permanent
                  researcher at the CNRS and Faculty Associate at the Harvard
                  University Berkman Center.
                </li>
                <li>
                  <a target="blank" href="https://www.basdevant.tech/">
                    Adrien Basdevant
                  </a>
                  &nbsp;is an attorney-at-law, member of the Paris bar and of
                  the French Digital Council. He is the author of 'The Empire of
                  Data'.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
