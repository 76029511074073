import React from "react";
import socialStyles from "./../styles/socials.module.css";
import twitterSocial from "./../assets/twitter-social.png";
import linkedinSocial from "./../assets/linkedin-social.png";
import mastodonSocial from "./../assets/mastodon-social.png";

const Socials = () => {
  return (
    <div className={socialStyles.container}>
      <a target="blank" href="https://twitter.com/aiforensics_org">
        <img src={twitterSocial} alt="twitter" />
      </a>
      <a target="blank" href="https://www.linkedin.com/company/76251843/">
        <img src={linkedinSocial} alt="linkedin" />
      </a>
      <a target="blank" href="https://mastodon.social/@aiforensics_org">
        <img src={mastodonSocial} alt="mastodon" />
      </a>
    </div>
  );
};

export default Socials;
