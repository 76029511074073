import React from "react";
import dfflogo from "./../assets/dff-logo.png";
import europeanlogo from "./../assets/european-logo.png";
import mozillalogo from "./../assets/mozilla-logo.jpg";
import ngilogo from "./../assets/ngi-logo.png";
import ngialogo from "./../assets/ngia-logo.png";
import resetlogo from "./../assets/reset-logo.png";
import sidnlogo from "./../assets/sidn-logo.png";

import logoSpriteStyles from "./../styles/logosprite.module.css";

const LogoSprite = () => {
  return (
    <div>
      <h3>We are trusted by:</h3>
      <div className={logoSpriteStyles.logo_container}>
        <div className={logoSpriteStyles.first_chunk}>
          <img src={mozillalogo} className="mozilla-logo" alt="mozilla-logo" />
          <img
            src={europeanlogo}
            className={logoSpriteStyles.europeanLogo}
            alt="european-logo"
          />
          <img src={resetlogo} className="reset-logo" alt="reset-logo" />
        </div>
        <div className={logoSpriteStyles.second_chunk}>
          <img src={dfflogo} className="dff-logo" alt="dff-logo" />
          <img src={ngilogo} className="ngi-logo" alt="ngi-logo" />
          <img src={ngialogo} className="ngia-logo" alt="ngia-logo" />
        </div>
        <div className={logoSpriteStyles.second_chunk}>
          <img
            src={sidnlogo}
            className={logoSpriteStyles.sidnLogo}
            alt="sidn-logo"
          />
        </div>
        <div className={logoSpriteStyles.second_chunk}>
          <span className={logoSpriteStyles.logo_in_text}>
            With the support of <strong>Open Society Foundations</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LogoSprite;
