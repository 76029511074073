import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import navbarStyles from "./../styles/navbar.module.css";
import aiflogo from "./../assets/aif-logo.svg";

const NavBar = () => {
  const { pathname } = useLocation();
  return (
    <div className={navbarStyles.navbar}>
      <Link to="/" className={navbarStyles.link}>
        <img src={aiflogo} className="aif-logo" alt="aif-logo" />
      </Link>
      {pathname !== "/about" && (
        <Link to="/about" className={navbarStyles.navigate}>
          About
        </Link>
      )}
    </div>
  );
};

export default NavBar;
